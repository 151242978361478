import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CaseService } from '../../../case.service';
import { ToastrService } from 'ngx-toastr';
import { finalize, map, startWith } from 'rxjs/operators';
import { CreditorService } from '../../../../creditor/creditor.service';
import { CaseCreditor } from 'projects/_base-shared/models/Case/CaseCreditor';
import { MiscConfigService } from '../../../../config/misc-config/misc-config.service';
import { CaseCreditorService } from '../../../case-creditor.service';
import { CasePaymentPlanService } from '../../../case-payment-plan.service';
import {Observable} from 'rxjs';
import { DateTime } from 'luxon';
import { environment } from '../../../../../../environments/environment';
import { saveAs } from 'file-saver';

@Component({
  templateUrl: './display.component.html',
})
export class DemandMonitorioComponent implements OnInit {
  public form: UntypedFormGroup;
  public formActive = false;
  public isLoading = 0;
  public caseCreditors: CaseCreditor[] = [];
  public provinceCategories    = Array<any>();
  public allLocations          = Array<any>();
  public partido_judicial      = new FormControl();
  public statusFormControlName = 'partido_judicial';
  public selectedIds: any[] = [];
  public demandaDocuments: { 
    debt_certificate: { file: { path: string } },
    burofax_document: { file: { path: string } },
    contract: { pdf_location: string },
  };
public storageUrl = environment.STORAGE_URL + '/';  
public hasBurofax = false;
public hasDebtCertificate = false;
public hasContract = false;

  constructor(public dialogRef: MatDialogRef<DemandMonitorioComponent>,
              private fb: UntypedFormBuilder,
              private toastr: ToastrService,
              private toast: ToastrService,
              private translate: TranslateService,
              private caseService: CaseService,
              private creditorService: CreditorService,
              public translateService: TranslateService,
              private configService: MiscConfigService,
              private caseCreditorService: CaseCreditorService,
              private casePaymentPlanService: CasePaymentPlanService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.getPartidoJudiciales();
    this.getDemandaDocuments();
    this.buildForm();
    
    console.log(this.data.case.creditors);
    this.data.case.creditors.forEach(creditor => {
      this.caseCreditors.push({
        id: creditor.pivot.id,
        reference_number: creditor.pivot.reference_number,

      });
    });
    this.selectedIds = this.data.selectedIds;
  }

  buildForm() {
    this.form = this.fb.group({
        partido_judicial: [null, Validators.required],
    });

    this.formActive = true;
  }

  closeDialog(value = null) {
    this.dialogRef.close(value);
  }

  submit(form: UntypedFormGroup): void {
    this.isLoading++;
    // partidoJudicial get the id
    const partidoJudicial = this.form.get('partido_judicial').value;
    this.caseCreditorService.sendDemandaMonitorio(this.data.case.id, {selected_ids: this.data.selectedIds, partido_judicial: partidoJudicial})
        .pipe(finalize(() => {
            console.log('finalize');
            this.isLoading--;
        }))
        .subscribe(
        value => {
            console.log(value);
            const fileName = 'demanda_' + DateTime.local().toFormat('yyyy-LL-dd_HH-mm') + '.doc';
            saveAs(value.body, fileName);
        },
        error => {
            if (error.error.errors.case.errors && error.error.errors.case.errors.length) {
                console.log(error.error.errors.case.errors);
            }
        });
  }

  private getPartidoJudiciales(): void {
      this.caseCreditorService.indexPartidoJudiciales()
          .subscribe(result => {
              this.provinceCategories = result.data;

              this.provinceCategories.forEach(province => {
                  this.allLocations.push(province);
                  province.partidos.forEach(partido => this.allLocations.push(partido));
              })
          },
          error => {
              this.toast.error(
              this.translateService.instant('CASES.single.draft.entity_appointer.save_entity.response.error', {
                  entity: this.translateService.instant('CASE_ENTITY.solicitor.model_name.singular'),
              }));
          }
      );
  }

  private getDemandaDocuments(): void {
    this.caseCreditorService.getDemandaDocuments(this.data.case.id, {selected_ids: this.data.selectedIds})
        .subscribe(result => {
            this.demandaDocuments = result.data;
            // if demandas_documents.burofax_document exists then set hasBurofax to true
            if (result.data.burofax_document && result.data.burofax_document.file) {
                this.hasBurofax = true;
            }
            // if demandas_documents.debt_certificate exists then set hasDebtCertificate to true
            if (result.data.debt_certificate && result.data.debt_certificate.file) {
                this.hasDebtCertificate = true;
            }
            // if demandas_documents.contract exists then set hasContract to true
            if (result.data.contract && result.data.contract.pdf_location) {
                this.hasContract = true;
            }
        },
        error => {
            this.toast.error(
            this.translateService.instant('CASES.single.draft.entity_appointer.save_entity.response.error', {
                entity: this.translateService.instant('CASE_ENTITY.solicitor.model_name.singular'),
            }));
        }
    );
  }

  public downloadFile(filePath: string): void {
    // download file from the api url and append the filepath
    window.open(filePath, '_blank');
  }
}
