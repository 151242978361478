import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { CaseCreditorStatus } from '../../../../../../_base-shared/models/Status/CaseCreditorStatus';
import { CaseCreditorStatusService } from '../case-creditor-status.service';
import {MainGlobalEventService} from '../../../_shared/services/main-global-event.service';
import {User} from '../../../../../../_base-shared/models/User/User';

@Component({
  selector:    'app-case-creditor-status-list',
  templateUrl: './case-creditor-status-list.component.html',
  styleUrls:   ['./case-creditor-status-list.component.scss']
})
export class CaseCreditorStatusListComponent implements OnInit{
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public authUser: User;
  public caseCreditorStatuses: CaseCreditorStatus[];
  public displayedColumns: string[] = ['id', 'name', 'parent_status', 'active', 'created_at', 'actions'];
  public actions: string[]          = ['Edit', 'Delete'];
  public isLoading                  = 0;
  public dataSource: MatTableDataSource<CaseCreditorStatus>;
  public paginatorConfig            = {
    pageIndex: 0,
    pageSize:  10,
    length:    1
  };

  public search                     = new UntypedFormControl('');

  constructor(
    private toastr: ToastrService,
    public translateService: TranslateService,
    private caseCreditorStatusService: CaseCreditorStatusService,
    private globalEventsService: MainGlobalEventService,
  ) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);

    this.fetchCaseCreditorStatuses();
    this.search.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
    )
      .subscribe(() => this.fetchCaseCreditorStatuses());
  }

  private fetchCaseCreditorStatuses(): void {
    this.dataSource = new MatTableDataSource<CaseCreditorStatus>([]);
    this.isLoading++;
    const data = {
      per_page: this.paginatorConfig.pageSize,
      page:     this.paginatorConfig.pageIndex + 1,
      search:   this.search.value,
    };
    this.caseCreditorStatusService.index(data).pipe(finalize(() => this.isLoading--))
      .subscribe(res => {
        this.caseCreditorStatuses           = res.data;
        this.dataSource             = new MatTableDataSource<CaseCreditorStatus>(res.data);
        this.dataSource.sort        = this.sort;
        this.paginatorConfig.length = res.meta.total;
      });
  }

  public paginatorChange($event: PageEvent): void {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;
    this.fetchCaseCreditorStatuses();
  }

  openDeleteDialog(statusId, $event): void {
    $event.preventDefault();
    Swal.fire({
      title:              this.translateService.instant('SHARED.warning'),
      text:               this.translateService.instant('SHARED.delete-item'),
      icon:               'warning',
      showCancelButton:   true,
      cancelButtonText:   this.translateService.instant('SHARED.cancel'),
      confirmButtonText:  this.translateService.instant('SHARED.delete'),
      confirmButtonColor: '#886AB5',
    }).then(res => {
      if (res.isConfirmed) {
        this.deleteCaseCreditorStatus(statusId);
      }
    });
  }

  deleteCaseCreditorStatus(statusId: number): void {
    this.caseCreditorStatusService.delete(statusId)
      .subscribe(res => {
          this.fetchCaseCreditorStatuses();
          this.toastr.success(this.translateService.instant('CASE_CREDITOR.case_creditor_status.list.actions.delete.result.success'));
        },
        error => {
          this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
        });
  }
}
