<!-- AGENT NAV -->
<ul *ngIf="authUser && authUser.role.is_staff && authUser.role_id !== 6" class="navbar-nav mr-auto">
  <!-- Dashboard -->
  <li class="nav-item-custom" *ngIf="authUser?.packager?.master" routerLinkActive="nav-item-custom-active"
      [routerLinkActiveOptions]="{ exact: true }">
    <a class="d-flex dropdown-btn" [matMenuTriggerFor]="dashboardMenu">
      {{ "NAVBAR.dashboard" | translate }}
      <mat-icon style="">expand_more</mat-icon>
    </a>
    <mat-menu #dashboardMenu="matMenu" class="dropdown-custom">
      <a routerLink="claims-dash" title="Claims dashboard" mat-menu-item>
        <span class="nav-link-text">{{"NAVBAR.claims-dashboard" | translate}}</span>
      </a>
    </mat-menu>
  </li>

  <!-- Cases -->
  <li routerLinkActive="nav-item-custom-active" class="nav-item">
    <a [routerLink]="['case-dashboard/claims']" title="Cases" class="nav-link">
      <span class="nav-link-text">{{ "NAVBAR.cases" | translate }}</span>
    </a>
  </li>

  <li *ngIf="!authUser?.packager?.master" routerLinkActive="nav-item-custom-active" class="nav-item">
    <a [routerLink]="['users']" title="Users" class="nav-link">
      <span class="nav-link-text">{{ "NAVBAR.users" | translate }}</span>
    </a>
  </li>

  <li *ngIf="!authUser?.packager?.master" routerLinkActive="nav-item-custom-active" class="nav-item">
    <a [routerLink]="['packagers', authUser.packager_id, 'details', 'general']" title="Config" class="nav-link">
      <span class="nav-link-text">Config</span>
    </a>
  </li>

  <li *ngIf="!authUser?.packager?.master" routerLinkActive="nav-item-custom-active" class="nav-item">
    <a [routerLink]="['drip-campaigns']" title="Drip Campaigns" class="nav-link">
      <span class="nav-link-text">{{ "NAVBAR.drip-campaigns" | translate }}</span>
    </a>
  </li>

  <!-- MANAGER & ADMIN NAV -->
  <ng-container *ngIf="authUser.packager?.master && authUser.role_id !== 3">
    <!-- Affiliate Cases-->
    <!-- <li routerLinkActive="nav-item-custom-active">
      <a [routerLink]="['affiliate-cases']" title="Affiliate Cases" class="nav-link">
        <span class="nav-link-text">{{ "NAVBAR.affiliate-cases" | translate }}</span>
      </a>
    </li> -->
    <!-- Payments -->
    <li routerLinkActive="nav-item-custom-active">
      <a [routerLink]="['payment']" title="Payments" class="nav-link">
        <span class="nav-link-text">{{ "NAVBAR.payments" | translate }}</span>
      </a>
    </li>

    <!-- Creditors -->
    <!--        <li routerLinkActive="active" *ngIf="authUser.role_id !==  3" class="nav-item">-->
    <!--          <a routerLink="creditors" title="Creditors" class="nav-link">-->
    <!--            <span class="nav-link-text">{{ "NAVBAR.creditors" | translate }}</span>-->
    <!--            <i *ngIf="unapproved" class="fal fa-exclamation-circle ml-1 text-danger"></i>-->
    <!--          </a>-->
    <!--        </li>-->
  </ng-container>

  <li class="nav-item-custom" *ngIf="authUser?.packager?.master" routerLinkActive="nav-item-custom-active">
    <a class="d-flex dropdown-btn" [matMenuTriggerFor]="addressBookMenu">
      {{ "NAVBAR.address-book" | translate }}
      <mat-icon style="">expand_more</mat-icon>
    </a>
    <mat-menu #addressBookMenu="matMenu" class="dropdown-custom-two">
      <a routerLink="creditors" title="Creditors" mat-menu-item *ngIf="authUser.role_id !==  3">
        <span class="nav-link-text">{{ "NAVBAR.creditors" | translate }}</span>
        <i *ngIf="unapproved" class="fal fa-exclamation-circle ml-1 text-danger"></i>
      </a>
      <a routerLink="affiliates" title="Affiliates" mat-menu-item
         *ngIf="authUser.role_id === 1 || authUser.role_id === 5  || authUser.id === 272658">
        <span class="nav-link-text">{{ "NAVBAR.affiliates" | translate }}</span>
      </a>
      <!-- <a routerLink="packagers" title="Packagers" mat-menu-item
         *ngIf="authUser.role_id === 1 || authUser.role_id === 5">
        <span class="nav-link-text">{{ "NAVBAR.packagers" | translate }}</span>
      </a> -->
      <a routerLink="notaries" title="Notaries" mat-menu-item>
        <span class="nav-link-text">Notaries</span>
      </a>
      <!-- <a routerLink="administrators" title="Administrators" mat-menu-item>
        <span class="nav-link-text">{{'NAVBAR.administrators' | translate}}</span>
      </a> -->
      <a routerLink="court" title="Court" mat-menu-item>
        <span class="nav-link-text">Court</span>
      </a>
      <!-- <a routerLink="town-halls" title="Court" mat-menu-item>
        <span class="nav-link-text">{{'NAVBAR.town_halls' | translate}}</span>
      </a> -->
      <a routerLink="solicitors" title="Court" mat-menu-item>
        <span class="nav-link-text">{{'NAVBAR.solicitors' | translate}}</span>
      </a>
    </mat-menu>
  </li>

  <!-- ADMIN ONLY NAV -->
  <ng-container *ngIf="authUser?.packager?.master && (authUser.role_id === 1 || authUser.role_id === 5)">
    <!-- Users -->
    <li class="nav-item-custom"  routerLinkActive="nav-item-custom-active">
      <a class="d-flex dropdown-btn" [matMenuTriggerFor]="usersMenu">
        {{ "NAVBAR.users" | translate }}
        <mat-icon style="">expand_more</mat-icon>
      </a>
      <mat-menu #usersMenu="matMenu" class="dropdown-custom">
        <a [routerLink]="['users']" title="Users" mat-menu-item>
          <span class="nav-link-text">{{ "NAVBAR.users" | translate }}</span>
        </a>
        <a [routerLink]="['teams']" title="Users" mat-menu-item>
          <span class="nav-link-text">{{ "TEAM.list.heading" | translate }}</span>
        </a>
      </mat-menu>
    </li>
  </ng-container>
  <!-- Distribution -->
  <li *ngIf="userIsAMami()" routerLinkActive="nav-item-custom-active" class="nav-item">
    <a [routerLink]="['/distribution']" title="Distribution" class="nav-link">
      <span class="nav-link-text">{{ "NAVBAR.distribution" | translate }}</span>
    </a>
  </li>
  <ng-container *ngIf="authUser?.packager?.master && (authUser.role_id === 1 || authUser.role_id === 5)">
    <li routerLinkActive="nav-item-custom-active">
      <a routerLink="reports/financial-reports" title="Reports" class="nav-link">
        <span class="nav-link-text">{{ "NAVBAR.financial-reports" | translate }}</span>
      </a>
    </li>
    <!-- Config -->
    <li class="nav-item-custom"  routerLinkActive="nav-item-custom-active">
      <a class="d-flex dropdown-btn" [matMenuTriggerFor]="configurationMenu">
        {{ "NAVBAR.configuration" | translate }}
        <mat-icon style="">expand_more</mat-icon>
      </a>
      <mat-menu #configurationMenu="matMenu" class="dropdown-custom">
        <a routerLink="notification" title="Notifications" mat-menu-item>
          <span class="nav-link-text">{{ "NAVBAR.notifications" | translate }}</span>
        </a>
        <a routerLink="status" title="Statuses" mat-menu-item>
          <span class="nav-link-text">{{ "NAVBAR.statuses" | translate }}</span>
        </a>
        <a routerLink="case-creditor-statuses" title="Case Creditor statuses" mat-menu-item>
          <span class="nav-link-text">{{ "NAVBAR.case-creditor-statuses" | translate }}</span>
        </a>
        <a routerLink="status/payment" title="Case Creditor Payment Statuses" mat-menu-item>
          <span class="nav-link-text">Case Creditor Payment Statuses</span>
        </a>
        <a *ngIf="authUser?.role_id === 5" routerLink="call-statuses" title="Call statuses" mat-menu-item>
          <span class="nav-link-text">{{ "NAVBAR.call-statuses" | translate }}</span>
        </a>
        <a *ngIf="authUser?.role_id === 5" routerLink="packager-statuses" title="Packager statuses"
           mat-menu-item>
          <span class="nav-link-text">{{'NAVBAR.packager-statuses' | translate}}</span>
        </a>
        <a routerLink="task-templates" title="Task Templates" mat-menu-item>
          <span class="nav-link-text">{{ "NAVBAR.task-templates" | translate }}</span>
        </a>
        <a routerLink="sms" title="SMS Templates" mat-menu-item>
          <span class="nav-link-text">{{ "NAVBAR.sms-templates" | translate }}</span>
        </a>
        <a routerLink="email" title="Email Templates" mat-menu-item>
          <span class="nav-link-text">{{ "NAVBAR.email-templates" | translate }}</span>
        </a>
        <a routerLink="system-event" title="System Events" mat-menu-item>
          <span class="nav-link-text">{{ "NAVBAR.system-events" | translate }}</span>
        </a>
        <a routerLink="drip-campaigns" title="Drip Campaigns" mat-menu-item>
          <span class="nav-link-text">{{ "NAVBAR.drip-campaigns" | translate }}</span>
        </a>
        <a routerLink="config/dialer" title="Dialer Configuration" mat-menu-item>
          <span class="nav-link-text">{{ "NAVBAR.dialer-config" | translate }}</span>
        </a>
        <a routerLink="misc-config" title="Misc Config" mat-menu-item>
          <span class="nav-link-text">{{ "NAVBAR.misc-config" | translate }}</span>
        </a>
        <a routerLink="one-time-fee" title="One Time Fee Type" mat-menu-item>
          <span class="nav-link-text">{{'NAVBAR.one-time-fee' | translate}}</span>
        </a>
      </mat-menu>
    </li>
  </ng-container>
  <!--Employees-->
  <!-- <li *ngIf="authUser?.packager?.master" routerLinkActive="active" class="nav-item">
    <a [routerLink]="['/employee-list']" title="Employees" class="nav-link">
      <span class="nav-link-text">{{ "NAVBAR.employees" | translate }}</span>
    </a>
  </li> -->
</ul>

<ul *ngIf="authUser && authUser.role.is_staff && authUser.packager.master && authUser.role_id === 6"
    class="navbar-nav mr-auto">
  <!-- Dashboard -->
  <li class="nav-item-custom"  routerLinkActive="nav-item-custom-active" [routerLinkActiveOptions]="{ exact: true }">
    <a class="d-flex dropdown-btn" [matMenuTriggerFor]="dashboardMenu">
      {{ "NAVBAR.dashboard" | translate }}
      <mat-icon style="">expand_more</mat-icon>
    </a>
    <mat-menu #dashboardMenu="matMenu" class="dropdown-custom-two">
      <a routerLink="" title="Sales leaderboard" mat-menu-item>
        <span class="nav-link-text">{{"DASHBOARD.sales-leaderboard" | translate}}</span>
      </a>
    </mat-menu>
  </li>

  <li class="nav-item-custom"  routerLinkActive="nav-item-custom-active">
    <a class="d-flex dropdown-btn" [matMenuTriggerFor]="reportsTwoMenu">
      {{ "NAVBAR.reports" | translate }}
      <mat-icon style="">expand_more</mat-icon>
    </a>
    <mat-menu #reportsTwoMenu="matMenu" class="dropdown-custom-two">
      <a routerLink="reports/headline-reports" title="Headline Reports" mat-menu-item>
        <span class="nav-link-text">{{"NAVBAR.headline-reports" | translate}}</span>
      </a>
    </mat-menu>
  </li>
</ul>
