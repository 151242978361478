import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { CaseCreditorStatus } from '../../../../../../_base-shared/models/Status/CaseCreditorStatus';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { CaseCreditorStatusService } from '../case-creditor-status.service';

@Component({
  selector: 'app-case-creditor-status-editor',
  templateUrl: './case-creditor-status-editor.component.html',
  styleUrls: ['./case-creditor-status-editor.component.scss'],
})
export class CaseCreditorStatusEditorComponent implements OnInit {
  public isLoading = 0;
  public form: UntypedFormGroup;
  public editorType: 'create' | 'edit';
  public caseCreditorStatus: CaseCreditorStatus;
  public authUser: User;
  public parentStatuses: CaseCreditorStatus[];
  public allowedUsers = [2, 36, 50, 275019];

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private router: Router,
    private caseCreditorStatusService: CaseCreditorStatusService,
    private toastr: ToastrService,
    public translateService: TranslateService,
    private globalEventService: MainGlobalEventService,
  ) {
  }

  ngOnInit(): void {
    this.editorType = this.route.snapshot.data.editor;
    this.globalEventService.authUser$.subscribe(user => this.authUser = user);
    if (this.editorType === 'edit') {
      this.fetchStatus(+this.route.snapshot.paramMap.get('id'));
      if (!(this.authUser.role_id === 1 || this.authUser.role_id === 5 || this.authUser.id === 2592)) {
        this.router.navigate(['/']);
      }
    } else {
      this.caseCreditorStatus = new CaseCreditorStatus();
      this.buildForm();
    }
    this.fetchParentStatuses();

  }

  private fetchStatus(caseCreditorStatusId: number): void {
    this.isLoading++;
    this.caseCreditorStatusService.get(caseCreditorStatusId).pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.caseCreditorStatus = result.data;
        this.buildForm();
      });
  }

  fetchParentStatuses(): void {
    this.isLoading++;
    this.caseCreditorStatusService.indexParentStatuses().pipe(finalize(() => this.isLoading--))
      .subscribe(result => this.parentStatuses = result.data);
  }

  private buildForm(): void {
    this.form = this.fb.group({
      name_es: [
        {value:    this.caseCreditorStatus.name_es,
          disabled: !this.allowedUsers.includes(this.authUser.id),
        }, Validators.required],
      name_en: [
        {value:    this.caseCreditorStatus.name_en,
          disabled: !this.allowedUsers.includes(this.authUser.id),
        }, Validators.required],
      case_creditor_status_id: [
        {value:    this.caseCreditorStatus.case_creditor_status_id,
          disabled: !this.allowedUsers.includes(this.authUser.id),
        }],
      active: this.caseCreditorStatus?.active ? true : false
    });
  }

  public onSubmit(): void {
    if (this.editorType === 'create') {
      this.caseCreditorStatusService.store(this.form.value)
        .subscribe(res => {
            this.router.navigateByUrl('/case-creditor-statuses');
            this.toastr.success(
              `${ this.translateService.instant(
                'CONFIG.case-creditor-status.case-creditor-status') } ${ this.translateService.instant(
                'SHARED.item-added') }`);
          },
          error => {
            this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
          });
    } else {
      // if case_creditor_status_id is null, remove it from the form
      if (!this.form.value.case_creditor_status_id) {
        delete this.form.value.case_creditor_status_id;
      }
      this.caseCreditorStatusService.update(this.caseCreditorStatus.id, this.form.value)
        .subscribe(res => {
            this.router.navigateByUrl('/case-creditor-statuses');
            this.toastr.success(
              `${ this.translateService.instant(
                'CONFIG.case-creditor-status.case-creditor-status') } ${ this.translateService.instant(
                'SHARED.item-edited') }`);
          },
          error => {
            this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
          },
        );
    }
  }

}
