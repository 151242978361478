<div class="d-flex flex-column h-100">
    <h2>{{ 'CASE_CREDITOR.demanda_monitorio.title' | translate }}</h2>
  
    <form [formGroup]="form">
        <div class="form-group">
            <div class="row">
                <!-- Claim Reference -->
                <div class="col-md-12 col-md-offset-2">
                    <app-input *ngIf="provinceCategories?.length" type="select"
                               [formControlName]="statusFormControlName"
                               appearance="standard" [searchable]="true" [multiple]="false"
                               label="Partido Judicial" [optGroupProperty]="'partidos'"
                               [selectOptions]="provinceCategories" [optGroupLabel]="'province'"
                               [selectLabel]="'location'" [selectValue]="'id'" class="full-width"
                               [showClear]="false" [fullWidth]="true">
                    </app-input>
                </div>
            </div>
        </div>
    </form>
    <div class="row">
        <div class="col-md-8">
            <button *ngIf="!isLoading" mat-raised-button color="primary" (click)="downloadFile(storageUrl + demandaDocuments.contract.pdf_location)" [disabled]="hasContract === false">
                {{ 'CASE_CREDITOR.demanda_monitorio.contract' | translate }}
            </button>
            <button *ngIf="!isLoading" mat-raised-button color="primary" (click)="downloadFile(storageUrl + demandaDocuments.burofax_document.file.path)" [disabled]="hasBurofax === false" class="ml-3">
                {{ 'CASE_CREDITOR.demanda_monitorio.burofax' | translate }}
            </button>
            <button *ngIf="!isLoading" mat-raised-button color="primary" (click)="downloadFile(storageUrl + demandaDocuments.debt_certificate.file.path)" [disabled]="hasDebtCertificate === false" class="ml-3">
                {{ 'CASE_CREDITOR.demanda_monitorio.debt_certificate' | translate }}
            </button>
        </div>
        <div class="col-md-4 text-right mt-auto mb-5 d-flex justify-content-end align-items-center">
  
            <button type="button" mat-stroked-button color="primary" class="mr-3"
                    (click)="closeDialog()" data-dismiss="modal">{{ "CASE_CREDITOR.demanda_monitorio.close" | translate }}</button>
            <button *ngIf="!isLoading" [disabled]="hasBurofax === false" mat-raised-button color="primary" (click)="submit(form)">
              {{ 'CASE_CREDITOR.demanda_monitorio.download_word' | translate }}
            </button>
            <mat-spinner *ngIf="isLoading" diameter="20" color="accent"></mat-spinner>
          </div>
    </div>
  </div>
  