import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { Status } from '../../../../../_base-shared/models/Status/Status';
import { StatusCategory } from '../../../../../_base-shared/models/Status/StatusCategory';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class StatusService extends MainBaseApiService {

  index(data = {}): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/statuses`, {params: data})
        .pipe(catchError(response => this.handleError(response)));
  }

  get(statusId: number): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/statuses/${ statusId }`)
        .pipe(catchError(response => this.handleError(response)));
  }

  indexCategoriesWithStatuses(): Observable<LaravelResourceResponse<Array<StatusCategory>>> {
    return this.http.get<LaravelResourceResponse<Array<StatusCategory>>>(
        this.apiUrl + '/statuses/by-parent', {params: {all: 1, select_all: 1} as any},
    ).pipe(catchError(response => this.handleError(response)));
  }

  store(status: Status): Observable<LaravelResourceResponse<Status>> {
    return this.http.post<LaravelResourceResponse<Status>>(`${ this.apiUrl }/statuses`, status)
        .pipe(catchError(response => this.handleError(response)));
  }

  update(statusId: number, status: Status): Observable<LaravelResourceResponse<Status>> {
    return this.http.put<LaravelResourceResponse<Status>>(`${ this.apiUrl }/statuses/${ statusId }`, status)
        .pipe(catchError(response => this.handleError(response)));
  }

  delete(statusId: number): Observable<LaravelResourceResponse> {
    return this.http.delete<LaravelResourceResponse>(`${ this.apiUrl }/statuses/${ statusId }`)
        .pipe(catchError(response => this.handleError(response)));
  }

  getCaseCreditorStatus(statusId: number): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/case-creditor-statuses/${ statusId }`)
      .pipe(catchError(response => this.handleError(response)));
  }

  //
  // case creditor payment statuses
  //
  indexPaymentStatuses(data): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/statuses/${data.type}/payment`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  storePaymentStatus(data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/statuses/${data.type}/payment`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  deletePaymentStatus(data): Observable<LaravelResourceResponse> {
    return this.http.delete<LaravelResourceResponse>(`${ this.apiUrl }/statuses/${data.type}/payment/${data.paymentStatusId}`)
      .pipe(catchError(response => this.handleError(response)));
  }

}
