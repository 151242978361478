<div class="card shadow mb-4" style="height: 100%">
  <div class="card-header">
    <div class="d-flex justify-content-between align-items-center" style="height: 40px">
      <div>
        <h3>{{ "CASES.single.general.status_editor.heading" | translate }}</h3>
      </div>
      <div *ngIf="!isEditing" class="col-5 text-right">
        <button mat-icon-button color="primary" (click)="switchEdit($event)" class=" p-0">
          <mat-icon>settings</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="card-body d-flex flex-column">
    <div *ngIf="isLoading" class="pt-4 pl-4">
      <mat-spinner class="m-0 m-auto"></mat-spinner>
    </div>

    <app-server-response [response]="serverResponse"></app-server-response>
    <form [formGroup]="form" [hidden]="isLoading" class="disabled-form" (ngSubmit)="submitForm(form)">
      <!-- Status ID -->
      <div class="row align-items-baseline" style="padding-left: 12px">
        <app-input type="select" formControlName="status_id" appearance="standard"
                   [label]="'CASES.single.general.status_editor.status' | translate"
                   [selectOptions]="creditorStatuses" [selectLabel]="['name']" [selectValue]="'id'" class="full-width"
                   [showClear]="false" [extraLabel]="true" [fullWidth]="true" [showLabel]="false">
        </app-input>
      </div>
      <!-- Payment status -->
      <div class="row align-items-baseline" style="padding-left: 12px">
        <app-input type="select" formControlName="payment_status_id" appearance="standard" [searchable]="true"
                   [label]="'CASES.single.general.status_editor.claim_payment_status' | translate"
                   [selectOptions]="claimPaymentStatuses" [selectLabel]="['name']" [selectValue]="'id'"
                   class="full-width"
                   [showClear]="false" [extraLabel]="true" [fullWidth]="true" [showLabel]="false">
        </app-input>
      </div>
      <div class="row align-items-baseline" style="padding-left: 12px">
        <app-input type="select" formControlName="legal_payment_status_id" appearance="standard" [searchable]="true"
                   [label]="'CASES.single.general.status_editor.legal_payment_status' | translate"
                   [selectOptions]="legalPaymentStatuses" [selectLabel]="['name']" [selectValue]="'id'"
                   class="full-width"
                   [showClear]="false" [extraLabel]="true" [fullWidth]="true" [showLabel]="false">
        </app-input>
      </div>
      <!-- Product ID -->
      <div class="row align-items-baseline" style="padding-left: 12px">
        <app-input type="select" formControlName="case_creditor_product_id" appearance="standard"
                   [label]="'CASES.single.general.status_editor.product' | translate"
                   [selectOptions]="creditorProducts" [selectLabel]="['name']" [selectValue]="'id'" class="full-width"
                   [showClear]="false" [extraLabel]="true" [fullWidth]="true" [showLabel]="false">
        </app-input>
      </div>
      <!-- Appointed Court -->
      <div class="row align-items-center" style="min-height: 40px">
        <mat-label class="col-6">
          Appointed Court
        </mat-label>
        <div class="col-6 text-right">
          <p *ngIf="caseCreditor?.appointed_court">{{ caseCreditor.appointed_court?.court?.name }}</p>
          <p *ngIf="!caseCreditor?.appointed_court">/</p>
        </div>
      </div>
      <!-- Procurador -->
      <div class="row align-items-center" style="min-height: 40px">
        <mat-label class="col-6">
          Procurador
        </mat-label>
        <div class="col-6 text-right">
          <p
            *ngIf="caseCreditor?.case_creditor_entities?.solicitor">{{ caseCreditor?.case_creditor_entities?.solicitor?.name }}</p>
          <p *ngIf="!caseCreditor?.case_creditor_entities?.solicitor">/</p>
        </div>
      </div>
      <!-- Creditor name -->
      <div class="row align-items-center" style="min-height: 40px">
        <mat-label class="col-6">
          Creditor name
        </mat-label>
        <div class="col-6 text-right">
          <p *ngIf="caseCreditor?.creditor">{{ caseCreditor?.creditor?.name }}</p>
          <p *ngIf="!caseCreditor?.creditor">/</p>
        </div>
      </div>
      <div *ngIf="isEditing" class="mt-auto">
        <div class="row pt-3">
          <div class="col-12 text-right">
            <button mat-button color="primary" class="mr-3" (click)="switchEdit($event)">
              {{ 'SHARED.cancel' | translate }}
            </button>

            <app-spinner-btn type="submit" [loading]="isSubmitting" color="primary">
              {{ 'SHARED.save' | translate }}
            </app-spinner-btn>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
