import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainPermissionGuard } from '../../_shared/guards/main-permission-guard.service';
import { StatusEditorComponent } from './status-editor/status-editor.component';
import { StatusListComponent } from './status-list/status-list.component';
import { PaymentStatusListComponent } from './payment-status/payment-status.component';

const routes: Routes = [
  {
    path:     'status',
    children: [
      {path: '', canActivate: [MainPermissionGuard], component: StatusListComponent},
      {path: 'payment', canActivate: [MainPermissionGuard], component: PaymentStatusListComponent},
      {path: 'create', canActivate: [MainPermissionGuard], component: StatusEditorComponent, data: {editor: 'create'}},
      {path: ':id/edit', canActivate: [MainPermissionGuard], component: StatusEditorComponent, data: {editor: 'edit'}},
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})

export class StatusRoutingModule {
}
