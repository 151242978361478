import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SharedModule } from '../../_shared/shared.module';
import { AppDocumentModule } from '../app-document/app-document.module';
import {
  CaseCreditorStatusEditorComponent
} from '../case-detail/case-creditor-detail/case-creditor-general-detail/case-creditor-status-editor/case-creditor-status-editor.component';
import { DocumentComponent } from '../document/document.component';
import { TaskModule } from '../task/task.module';
import { UserModule } from '../user/user.module';
import { CaseBulkActionModalComponent } from './case-bulk-action-modal/case-bulk-action-modal.component';
import { CaseDashboardComponent } from './case-dashboard/case-dashboard.component';
import { CaseCreditorDetailComponent } from './case-detail/case-creditor-detail/case-creditor-detail.component';
import {
  CaseCreditorDocumentListComponent
} from './case-detail/case-creditor-detail/case-creditor-document-list/case-creditor-document-list.component';
import {
  CaseCreditorDraftComponent
} from './case-detail/case-creditor-detail/case-creditor-draft/case-creditor-draft.component';
import {
  CaseCreditorEntityEditorComponent
} from './case-detail/case-creditor-detail/case-creditor-draft/case-creditor-entity-editor/case-creditor-entity-editor.component';
import {
  CaseCreditorGeneralDetailComponent
} from './case-detail/case-creditor-detail/case-creditor-general-detail/case-creditor-general-detail.component';
import {
  CaseCreditorRelationListComponent
} from './case-detail/case-creditor-detail/case-creditor-general-detail/case-creditor-relation-list/case-creditor-relation-list.component';
import {
  CaseCreditorInstallmentListComponent
} from './case-detail/case-creditor-detail/case-creditor-installment-list/case-creditor-installment-list.component';
import { AdditionalInputComponent } from './case-detail/case-creditor-list/additional-input/additional-input.component';
import { CaseCreditorListComponent } from './case-detail/case-creditor-list/case-creditor-list.component';
import { CourtFeeModalComponent } from './case-detail/case-creditor-list/court-fee-modal/court-fee-modal.component';
import {
  CaseDepartmentUserEditorComponent,
} from './case-detail/case-detail-general/case-department-user-editor/case-department-user-editor.component';
import { CaseGeneralDetailComponent } from './case-detail/case-detail-general/case-general-detail.component';
import {
  CaseQuickClientEditorComponent,
} from './case-detail/case-detail-general/case-quick-client-editor/case-quick-client-editor.component';
import {
  CaseContractEditorComponent,
} from './case-detail/case-detail-general/case-creditor-contract-editor/case-contract-editor.component';
import {
  CaseAepListComponent,
} from './case-detail/case-detail-general/case-relation-list/case-aep-list/case-aep-list.component';
import {
  CaseCallStatusLogComponent
} from './case-detail/case-detail-general/case-relation-list/case-call-status-log/case-call-status-log.component';
import {
  CaseContractListComponent,
} from './case-detail/case-detail-general/case-relation-list/case-contract-list/case-contract-list.component';
import {
  CaseCreditorNoteListComponent,
} from './case-detail/case-detail-general/case-relation-list/case-creditor-note-list/case-creditor-note-list.component';
import {
  CaseNotesComponent,
} from './case-detail/case-detail-general/case-relation-list/case-notes/case-notes.component';
import {
  CaseNotificationListComponent,
} from './case-detail/case-detail-general/case-relation-list/case-notification-list/case-notification-list.component';
import {
  EmailShadowDomComponent
} from './case-detail/case-detail-general/case-relation-list/case-notification-list/email-shadow-dom/email-shadow-dom.component';
import {
  CasePaymentStatusLogComponent,
} from './case-detail/case-detail-general/case-relation-list/case-payment-status-log/case-payment-status-log.component';
import {
  CaseProposalListComponent,
} from './case-detail/case-detail-general/case-relation-list/case-proposal-list/case-proposal-list.component';
import {
  CaseRelationListComponent,
} from './case-detail/case-detail-general/case-relation-list/case-relation-list.component';
import {
  CaseStatusLogComponent,
} from './case-detail/case-detail-general/case-relation-list/case-status-log/case-status-log.component';
import {
  CaseTaskListComponent,
} from './case-detail/case-detail-general/case-relation-list/case-task-list/case-task-list.component';
import {
  CaseStatusEditorComponent,
} from './case-detail/case-detail-general/case-status-editor/case-status-editor.component';
import {
  CaseUserPasswordModalComponent
} from './case-detail/case-detail-general/case-user-password-modal/case-user-password-modal.component';
import { CaseDetailComponent } from './case-detail/case-detail.component';
import { AllBankFilesComponent } from './case-detail/case-document-list-legacy/all-bank-files/all-bank-files.component';
import {
  AllCourtFilesComponent
} from './case-detail/case-document-list-legacy/all-court-files/all-court-files.component';
import {
  AllCreditorFilesComponent,
} from './case-detail/case-document-list-legacy/all-creditor-files/all-creditor-files.component';
import { AllFilesComponent } from './case-detail/case-document-list-legacy/all-files/all-files.component';
import {
  AllLegalFilesComponent
} from './case-detail/case-document-list-legacy/all-legal-files/all-legal-files.component';
import {
  CaseDocumentListLegacyComponent
} from './case-detail/case-document-list-legacy/case-document-list-legacy.component';
import { RequestComponent } from './case-detail/case-document-list-legacy/request/request.component';
import { RequiredComponent } from './case-detail/case-document-list-legacy/required/required.component';
import { CaseDocumentListDeprecatedConceptComponent } from './case-detail/case-document-list-deprecated-concept/case-document-list-deprecated-concept.component';
import {
  DocumentRequestListComponent,
} from './case-detail/case-document-list-deprecated-concept/document-request-list/document-request-list.component';
import {
  RequestedDocumentUploaderComponent,
} from './case-detail/case-document-list-deprecated-concept/requested-document-uploader/requested-document-uploader.component';
import { CaseDocumentListComponent } from './case-detail/case-document-list/case-document-list.component';
import {
  CaseDocumentUploadListComponent
} from './case-detail/case-document-list/case-document-upload-list/case-document-upload-list.component';
import {
  AdministratorModalComponent
} from './case-detail/case-draft/administrator-modal/administrator-modal.component';
import { AppointCourtModalComponent } from './case-detail/case-draft/appoint-court-modal/appoint-court-modal.component';
import {
  AppointedCourtEditorComponent,
} from './case-detail/case-draft/appointed-court-editor/appointed-court-editor.component';
import { CaseDraftComponent } from './case-detail/case-draft/case-draft.component';
import { NotaryModalComponent } from './case-detail/case-draft/notary-modal/notary-modal.component';
import { ProposalModalComponent } from './case-detail/case-draft/proposal-modal/proposal-modal.component';
import { SolicitorModalComponent } from './case-detail/case-draft/solicitor-modal/solicitor-modal.component';
import {
  CaseInstallmentAmountEditorComponent,
} from './case-detail/case-payment/case-installment-amount-editor/case-installment-amount-editor.component';
import {
  CaseInstallmentListComponent,
} from './case-detail/case-payment/case-installment-list/case-installment-list.component';
import {
  RecordPaymentModalComponent,
} from './case-detail/case-payment/case-installment-list/record-payment-modal/record-payment-modal.component';
import { CasePaymentListComponent } from './case-detail/case-payment/case-payment-list/case-payment-list.component';
import {
  EditHistoryModalComponent,
} from './case-detail/case-payment/case-payment-list/edit-history-modal/edit-history-modal.component';
import {
  CasePaymentPlanGeneratorComponent,
} from './case-detail/case-payment/case-payment-plan-generator/case-payment-plan-generator.component';
import {
  CasePaymentRequestListComponent,
} from './case-detail/case-payment/case-payment-request-list/case-payment-request-list.component';
import { CasePaymentComponent } from './case-detail/case-payment/case-payment.component';
import {
  CaseTransactionListComponent,
} from './case-detail/case-payment/case-transaction-list/case-transaction-list.component';
import { FloatingNoteComponent } from './case-detail/floating-note/floating-note.component';
import { QuickNoteComponent } from './case-detail/quick-note/quick-note.component';
import { CaseEditorPrefillComponent } from './case-editor-prefill/case-editor-prefill.component';
import { CaseCreditorEditorComponent } from './case-editor/case-creditor-editor/case-creditor-editor.component';
import { CaseEditorComponent } from './case-editor/case-editor.component';
import { CaseFinanceOverviewComponent } from './case-editor/case-finance-summary/case-finance-overview.component';
import { CaseGeneralEditorComponent } from './case-editor/case-general-editor/case-general-editor.component';
import {
  CaseIdCardEditorComponent,
} from './case-editor/case-payment-editor/case-id-card-editor/case-id-card-editor.component';
import {
  CasePaymentEditorComponent as CasePaymentEditorComponent,
} from './case-editor/case-payment-editor/case-payment-editor.component';
import { CaseUserMergeComponent } from './case-editor/case-payment-editor/case-user-merge/case-user-merge.component';
import { CaseListFiltersComponent } from './case-list/case-list-filters/case-list-filters.component';
import { CaseListComponent } from './case-list/case-list.component';
import { CaseRoutingModule } from './case-routing.module';
import { AssetsSnapshotListComponent } from './case-snapshot/assets-snapshot-list/assets-snapshot-list.component';
import { CaseSnapshotComponent } from './case-snapshot/case-snapshot.component';
import { ClientSnapshotListComponent } from './case-snapshot/client-snapshot-list/client-snapshot-list.component';
import { CreditorSnapshotListComponent } from './case-snapshot/creditor-snapshot-list/creditor-snapshot-list.component';
import { ExpancesSnapshotListComponent } from './case-snapshot/expances-snapshot-list/expances-snapshot-list.component';
import { GeneralSnapshotListComponent } from './case-snapshot/general-snapshot-list/general-snapshot-list.component';
import { IncomesSnapshotListComponent } from './case-snapshot/incomes-snapshot-list/incomes-snapshot-list.component';
import { PaymentStatusModalComponent } from './case-detail/case-payment/payment-status-modal/payment-status-modal.component';
import { CaseCreditorStatusLogComponent } from './case-detail/case-detail-general/case-relation-list/case-creditor-status-log/case-creditor-status-log.component';
import { SplitTermComponent } from './case-detail/case-payment/split-term/split-term.component';
import { WinAmountComponent } from './case-detail/case-creditor-list/win-amount-modal/win-amount.component';
import { LegalFeeComponent } from './case-detail/case-creditor-list/legal-fee-modal/legal-fee.component';
import { ClaimListFiltersComponent } from './claim-list/claim-list-filters/claim-list-filters.component';
import { ClaimListComponent } from './claim-list/claim-list.component';
import { GenerateDemandComponent } from './case-detail/case-creditor-detail/case-creditor-draft/generate-demand/demand.component';
import { DemandMonitorioComponent } from './case-detail/case-creditor-list/demanda-monitorio/display.component';

@NgModule({
  declarations: [
    CaseListComponent,
    CaseEditorComponent,
    CaseGeneralEditorComponent,
    CaseCreditorEditorComponent,
    CasePaymentEditorComponent,
    CaseDetailComponent,
    CaseGeneralDetailComponent,
    CaseDocumentListLegacyComponent,
    CaseDocumentListDeprecatedConceptComponent,
    CaseDocumentListComponent,
    CaseDocumentUploadListComponent,
    RequestComponent,
    AllFilesComponent,
    RequiredComponent,
    CasePaymentListComponent,
    RecordPaymentModalComponent,
    CaseNotesComponent,
    CaseInstallmentListComponent,
    EditHistoryModalComponent,
    CaseCreditorNoteListComponent,
    CaseStatusLogComponent,
    CasePaymentStatusLogComponent,
    CaseFinanceOverviewComponent,
    AllCreditorFilesComponent,
    AllLegalFilesComponent,
    CaseTransactionListComponent,
    CaseCreditorListComponent,
    AdditionalInputComponent,
    CaseDraftComponent,
    ProposalModalComponent,
    CaseContractListComponent,
    CaseTaskListComponent,
    CaseEditorPrefillComponent,
    CaseProposalListComponent,
    CaseListFiltersComponent,
    QuickNoteComponent,
    AdministratorModalComponent,
    CaseNotificationListComponent,
    CaseDashboardComponent,
    AllBankFilesComponent,
    NotaryModalComponent,
    CaseAepListComponent,
    AllCourtFilesComponent,
    CasePaymentRequestListComponent,
    DocumentRequestListComponent,
    CaseDepartmentUserEditorComponent,
    CaseIdCardEditorComponent,
    CaseRelationListComponent,
    CaseStatusEditorComponent,
    CaseQuickClientEditorComponent,
    CaseContractEditorComponent,
    RequestedDocumentUploaderComponent,
    CasePaymentComponent,
    AppointCourtModalComponent,
    AppointedCourtEditorComponent,
    CasePaymentPlanGeneratorComponent,
    CaseBulkActionModalComponent,
    CaseUserMergeComponent,
    SolicitorModalComponent,
    CaseInstallmentAmountEditorComponent,
    CaseUserPasswordModalComponent,
    CaseCallStatusLogComponent,
    FloatingNoteComponent,
    DocumentComponent,
    CaseSnapshotComponent,
    GeneralSnapshotListComponent,
    ClientSnapshotListComponent,
    AssetsSnapshotListComponent,
    IncomesSnapshotListComponent,
    ExpancesSnapshotListComponent,
    CreditorSnapshotListComponent,
    EmailShadowDomComponent,
    CaseCreditorDetailComponent,
    CaseCreditorGeneralDetailComponent,
    CaseCreditorDocumentListComponent,
    CaseCreditorDraftComponent,
    CaseCreditorInstallmentListComponent,
    CaseCreditorStatusEditorComponent,
    CaseCreditorRelationListComponent,
    CaseCreditorEntityEditorComponent,
    CourtFeeModalComponent,
    PaymentStatusModalComponent,
    CaseCreditorStatusLogComponent,
    SplitTermComponent,
    WinAmountComponent,
    LegalFeeComponent,
    ClaimListFiltersComponent,
    ClaimListComponent,
    GenerateDemandComponent,
    DemandMonitorioComponent
  ],
  imports:      [
    SharedModule,
    MatMenuModule,
    TaskModule,
    ImageCropperModule,
    NgxMatSelectSearchModule,
    NgxMatDatetimePickerModule,
    UserModule,
    AppDocumentModule,
    DragDropModule,
    CaseRoutingModule,
    MatAutocompleteModule,
  ],
    exports: [
        AllFilesComponent,
        CaseInstallmentListComponent,
        CasePaymentListComponent,
        CaseTransactionListComponent,
        CasePaymentRequestListComponent,
        ClaimListFiltersComponent,
    ],
})
export class CaseModule {
}
