import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CaseCreditorProduct } from 'projects/_base-shared/models/Product/CaseCreditorProduct';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../_base-shared/models/Case/Case';
import { CaseCreditor } from '../../../../../_base-shared/models/Case/CaseCreditor';
import { CasePublicDebt } from '../../../../../_base-shared/models/Case/CasePublicDebt';
import { AppDocument } from '../../../../../_base-shared/models/Document/AppDocument';
import { Creditor } from '../../../../../_base-shared/models/Entity/Creditor';
import { CaseCreditorInvoiceStatus } from '../../../../../_base-shared/models/Status/CaseCreditorInvoiceStatus';
import { CaseCreditorPaymentStatus } from '../../../../../_base-shared/models/Status/CaseCreditorPaymentStatus';
import { CaseCreditorStatus } from '../../../../../_base-shared/models/Status/CaseCreditorStatus';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

type SupportedEmails = 'SettlementDemand' | 'ExtrajudicialNotice' | 'SendInvoiceToCreditorNotification' | 'NewContract';
type SupportedDocTypes = 'settlement-demand' | 'extrajudicial-notice' | 'creditor-invoice';

@Injectable({
  providedIn: 'root',
})
export class CaseCreditorService extends MainBaseApiService {

  public indexClaims(params): Observable<LaravelResourceResponse<Array<CaseCreditor>>> {
    return this.http.get<LaravelResourceResponse<Array<CaseCreditor>>>(
      this.apiUrl + '/case-creditors', {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public indexCaseCreditorsPivot(caseId: number, params)
    : Observable<LaravelResourceResponse<{ secured: Array<Creditor>; unsecured: Array<Creditor>; claim: Array<Creditor> }>> {
    return this.http.get<LaravelResourceResponse<{
      secured: Array<Creditor>,
      unsecured: Array<Creditor>,
      claim: Array<Creditor>
    }>>(
      this.apiUrl + '/cases/' + caseId + '/creditors', {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public indexCaseCreditors(caseId: number, relations: Array<string> = [], requestData = {}): Observable<LaravelResourceResponse> {
    const params = MainBaseApiService.convertFiltersForRequest({...requestData, ...{with: relations}}, 'get');
    return this.http.get<LaravelResourceResponse<Array<CaseCreditor>>>(
      this.apiUrl + '/cases/' + caseId + '/case-creditors', {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public showCaseCreditor(
    caseId: number,
    caseCreditorId: number,
    relations: Array<string> = [],
    requestData: any         = {}): Observable<LaravelResourceResponse> {
    const params = MainBaseApiService.convertFiltersForRequest({...requestData, ...{with: relations}}, 'get');
    return this.http.get<LaravelResourceResponse<CaseCreditor>>(
      this.apiUrl + '/cases/' + caseId + '/case-creditors/' + caseCreditorId, {params},
    ).pipe(catchError(response => this.handleError(response)));
  }


  public updateCaseCreditor(caseId: number, caseCreditorId: number, requestData: any = {})
    : Observable<LaravelResourceResponse<CaseCreditor>> {
    return this.http.patch<LaravelResourceResponse<CaseCreditor>>(
      this.apiUrl + '/cases/' + caseId + '/case-creditors/' + caseCreditorId, requestData
    ).pipe(catchError(response => this.handleError(response)));
  }

  public upsert(caseId: number, requestData): Observable<LaravelResourceResponse<Case>> {
    return this.http.put<LaravelResourceResponse<Case>>(
      this.apiUrl + '/cases/' + caseId + '/creditors', requestData,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public indexCasePublicDebts(caseId: number, params): Observable<LaravelResourceResponse<Array<CasePublicDebt>>> {
    return this.http.get<LaravelResourceResponse<Array<CasePublicDebt>>>(
      this.apiUrl + '/cases/' + caseId + '/public-debts', {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public previewEmail(caseId: number, creditorId: number, documentType: SupportedEmails): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>(
      this.apiUrl + '/cases/' + caseId + '/creditors/' + creditorId + '/preview-email',
      {email_type: documentType},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public sendEmail(caseId: number, creditorId: number, documentType: SupportedEmails)
    : Observable<LaravelResourceResponse<AppDocument>> {
    return this.http.post<LaravelResourceResponse<AppDocument>>(
      this.apiUrl + '/cases/' + caseId + '/creditors/' + creditorId + '/send-email',
      {email_type: documentType},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public previewDocument(caseId: number, creditorId: number, documentType: SupportedDocTypes)
    : Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>(
      this.apiUrl + '/cases/' + caseId + '/creditors/' + creditorId + '/preview-document',
      {document_type: documentType},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public saveDocument(caseId: number, creditorId: number, documentType: 'settlement-demand' | 'extrajudicial-notice')
    : Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>(
      this.apiUrl + '/cases/' + caseId + '/creditors/' + creditorId + '/save-document',
      {document_type: documentType},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public saveAndDownloadDocument(caseId: number, creditorId: number, documentType: SupportedDocTypes): Observable<HttpResponse<Blob>> {
    return this.http.post(
      this.apiUrl + '/cases/' + caseId + '/creditors/' + creditorId + '/save-download-document',
      {document_type: documentType}, {observe: 'response', responseType: 'blob'},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getCaseCreditorStatuses(data: any = {}, relations: Array<string> = [], appends: Array<string> = [])
    : Observable<LaravelResourceResponse> {
    data.with    = relations;
    data.append  = appends;
    const params = MainBaseApiService.convertFiltersForRequest(data, 'get');
    return this.http.get<LaravelResourceResponse<Array<CaseCreditorStatus>>>(
      this.apiUrl + '/case-creditor-statuses', {params}
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getCaseCreditorPaymentStatuses(data: any = {}, relations: Array<string> = [], appends: Array<string> = [])
    : Observable<LaravelResourceResponse> {
    data.with    = relations;
    data.append  = appends;
    const params = MainBaseApiService.convertFiltersForRequest(data, 'get');
    return this.http.get<LaravelResourceResponse<Array<CaseCreditorPaymentStatus>>>(
      this.apiUrl + '/case-creditor-payment-statuses', {params}
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getCaseCreditorInvoiceStatuses(): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse<Array<CaseCreditorInvoiceStatus>>>(
      this.apiUrl + '/case-creditor-invoice-statuses',
    ).pipe(catchError(response => this.handleError(response)));
  }

  public updateCaseCreditorEntity(caseId: number, caseCreditorId: number, requestData): Observable<Object> {
    return this.http.put(
      this.apiUrl + '/cases/' + caseId + '/case-creditors/' + caseCreditorId + '/case-creditor-entity',
      requestData
    ).pipe(catchError(response => this.handleError(response)));
  }

  public indexProducts(data, relations: Array<string> = []): Observable<LaravelResourceResponse<Array<CaseCreditorProduct>>> {
    data.with    = relations;
    const params = MainBaseApiService.convertFiltersForRequest(data, 'get');
    return this.http.get<LaravelResourceResponse<Array<CaseCreditorProduct>>>(this.apiUrl + '/creditors/products', {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  public updateCourtFees(caseId: number, requestData): Observable<object> {
    return this.http.post(
      this.apiUrl + '/cases/' + caseId + '/case-creditors/case-creditor-court-fees',
      requestData
    ).pipe(catchError(response => this.handleError(response)));
  }


  public downloadAEPDComplaint(caseCreditorId: number): Observable<HttpResponse<Blob>>{
    return this.http.post(`${ this.apiUrl }/case-creditors/${ caseCreditorId }/download-complaint-letter`,
      {}, {
        observe: 'response', responseType: 'blob'
      });
  }

  public generateAEPDComplaint(caseCreditorId: number): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(
      `${ this.apiUrl }/case-creditors/${ caseCreditorId }/generate-complaint-letter`, {}
    ).pipe(catchError(response => this.handleError(response)));
  }

  public sendBuroFaxToClient(caseId: number, creditorIds): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(
      `${ this.apiUrl }/cases/${ caseId }/send-burofax-to-client`, { selected_ids: creditorIds }
    ).pipe(catchError(response => this.handleError(response)));
  }

  public generateDemandForCreditor(caseId: number, creditorId: number, requestData): Observable<HttpResponse<Blob>> {
    return this.http.post(
      `${ this.apiUrl }/cases/${ caseId }/case-creditors/${ creditorId }/generate-demand-v2`, requestData, {
        observe: 'response', responseType: 'blob',
      }).pipe(catchError(response => this.handleError(response)));
  }

  public indexInsuranceEntities() {
    return this.http.get<LaravelResourceResponse>(
      `${ this.apiUrl }/insurance-entities`
    ).pipe(catchError(response => this.handleError(response)));
  }

  public indexPartidoJudiciales() {
    return this.http.get<LaravelResourceResponse>(
      `${ this.apiUrl }/partidos-judiciales`
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getAllStatuses() {
    return this.http.get<LaravelResourceResponse>(
      `${ this.apiUrl }/case-creditor-parent-statuses`
    ).pipe(catchError(response => this.handleError(response)));
  }

  public sendDemandaMonitorio(caseId: number, data: { selected_ids: Array<number>, partido_judicial: any }): Observable<HttpResponse<Blob>> {
    return this.http.post(`${ this.apiUrl }/cases/${ caseId }/demanda-monitorio`, data, {
      observe: 'response',
      responseType: 'blob',
    }).pipe(catchError(response => this.handleError(response)));
  }

  public getDemandaDocuments(caseId: number, data: { selected_ids: Array<number>}): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(this.apiUrl + '/cases/' + caseId + '/demanda-documents', data)
      .pipe(catchError(response => this.handleError(response)));
  }
}
