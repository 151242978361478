import { NgModule } from '@angular/core';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { SharedModule } from '../../_shared/shared.module';
import { StatusEditorComponent } from './status-editor/status-editor.component';
import { StatusListComponent } from './status-list/status-list.component';
import { StatusRoutingModule } from './status-routing.module';

import { PaymentStatusListComponent } from './payment-status/payment-status.component';
import { CaseCreditorPaymentStatusEditorComponent } from './payment-status/payment-status-editor/editor.component';

@NgModule({
  declarations: [
    StatusListComponent,
    StatusEditorComponent,
    PaymentStatusListComponent,
    CaseCreditorPaymentStatusEditorComponent
  ],
  imports:      [
    SharedModule,
    MatAutocompleteModule,
    StatusRoutingModule,
  ],
})
export class StatusModule {
}
