import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize, first } from 'rxjs/operators';
import { CaseService } from '../../case/case.service';
import { CreditorService } from '../../creditor/creditor.service';
import { CaseCreditorProduct } from '../../../../../../_base-shared/models/Product/CaseCreditorProduct';
import { CaseCreditorService } from '../../case/case-creditor.service';
import { ca } from 'date-fns/locale';

@Component({
  selector:    'app-run-off-export',
  templateUrl: './run-off-export.component.html',
  styles:   [],
})
export class RunOffExportComponent implements OnInit {
  public form: UntypedFormGroup;
  public isLoading = 0;
  public title: string;
  public endPoint: string;
  public caseCreditorProducts: Array<CaseCreditorProduct> = [];
  public debtStatusOptions: Array<any>                    = [];
  public monthNames: { number: string, name: string }[]   = [];

    // get the data object that is being passed to the modal

  constructor(private fb: UntypedFormBuilder,
              private caseService: CaseService,
              private toastr: ToastrService,
              public dialogRef: MatDialogRef<RunOffExportComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private creditorService: CreditorService,
              private caseCreditorService: CaseCreditorService) {
  }

  ngOnInit(): void {
    this.title    = this.data.title;
    this.endPoint = this.data.type;
    this.buildForm();
    this.fetchCaseCreditorProducts();
    this.getDebtStatusOptions();
    this.populateMonthNames();
  }

  public sendReportData() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.isLoading++;
    if(this.endPoint === 'exportRunOffReports') {
      this.caseService.exportRunOffReports(this.form.value).pipe(finalize(() => this.isLoading--), first()).subscribe((data) => {
            this.dialogRef.close(true);
            this.toastr.success(data.message);
          },
          err => console.error(err));
    } else if(this.endPoint === 'clientCreditorReport') {
      this.caseService.clientCreditorReport(this.form.value).pipe(finalize(() => this.isLoading--), first()).subscribe((data) => {
            this.dialogRef.close(true);
            this.toastr.success(data.message);
          },
          err => console.error(err));
    } else if(this.endPoint === 'winAmountReport') {
      this.caseService.winAmountReport(this.form.value).pipe(finalize(() => this.isLoading--), first()).subscribe((data) => {
            this.dialogRef.close(true);
            this.toastr.success(data.message);
          },
          err => console.error(err));
    }
  }

  private buildForm() {
    this.form = this.fb.group({
      period_selector:      [null],
      last_term_start_date: [null,  Validators.required],
      last_term_end_date:   [null,  Validators.required],
      case_creditor_product_id: [null],
    });

    this.form.controls.period_selector.valueChanges.subscribe(value => {
      const [month, year] = value.split(' ');
      const monthNumber   = new Date(Date.parse(month + ' 1, '+ year)).getMonth()+1;
      const date          = new Date(year, monthNumber-1, 1);
      const lastDay       = new Date(date.getFullYear(),  monthNumber, 0);
      this.form.controls.last_term_start_date.setValue(date);
      this.form.controls.last_term_end_date.setValue(lastDay);
    });
  }

  private fetchCaseCreditorProducts(): void {
    this.isLoading++;
    this.creditorService.getCaseCreditorProducts().pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.caseCreditorProducts = result.data;
      });
  }

  public getDebtStatusOptions(): void {
    this.isLoading++;
    this.caseCreditorService.getCaseCreditorStatuses({select_all: 1})
      .pipe(finalize(() => {
        this.isLoading--;
      }))
      .subscribe(
        res => {
          this.debtStatusOptions = res.data;
          // sort the array by the name
          this.debtStatusOptions.sort((a, b) => a.name.localeCompare(b.name_es));
        });
  }

  public populateMonthNames(): void {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    for (let i = 0; i < 6; i++) {
      let month = currentMonth - i;
      let year = currentDate.getFullYear();
      if (month < 0) {
        month += 12;
        year--;
      }
      let monthName = new Date(year, month).toLocaleString('en-US', {month: 'long'});
      monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);
      this.monthNames.push({number: `${monthName} ${year}`, name: `${monthName} ${year}`});
    }
  }
}
