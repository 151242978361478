<div class="container">
    <div class="row pb-3">
        <div class="col-12">
            <h2>{{"CONFIG.payment-status.header" | translate}}</h2>
        </div>
        <div class="col-md-8">
        <button mat-raised-button color="primary" (click)="editCaseCreditorStatus(null, $event)" type="button">
            + {{"CONFIG.payment-status.add-status" | translate}}</button>
        </div>
        <div class="col-md-4 pt-md-0 pt-5">
            <div class="input-group bg-white shadow-inset-2" [class.has-length]="searchFocus">
                <div class="input-group-prepend">
                    <span class="input-group-text bg-transparent border-right-0">
                        <i class="fal fa-search"></i>
                    </span>
                </div>
                <input [formControl]="search"
                    type="text"
                    class="form-control
                                border-left-0 bg-transparent pl-0"
                    (focus)="toggleFocus(true)"
                    (blur)="toggleFocus(false)"
                    (change)="onChange()"
                    [placeholder]="translateService.instant('SHARED.search')">
            </div>
        </div>
    </div>
    
    <div class="mat-elevation-z8">
        <mat-tab-group (selectedTabChange)="yourFn($event)" #tabs  class="hides-tabs">
          <mat-tab [label]="'CONFIG.payment-status.tabs.claims' | translate"></mat-tab>
          <mat-tab [label]="'CONFIG.payment-status.tabs.legal' | translate"></mat-tab>
          <mat-tab [label]="'CONFIG.payment-status.tabs.unassigned' | translate"></mat-tab>
        </mat-tab-group>
        <table mat-table matSort [dataSource]="dataSource">
        <!-- Headers -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <!-- Position -->
        <ng-container matColumnDef="id">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>ID</th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>
        <!-- Name EN -->
        <ng-container matColumnDef="name_en">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CONFIG.payment-status.en-name" | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.name_en}} </td>
        </ng-container>
        <!-- Name ES -->
        <ng-container matColumnDef="name_es">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "CONFIG.payment-status.es-name" | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.name_es}} </td>
        </ng-container>
        <!-- Flag Case Active -->
        <ng-container matColumnDef="flag_case_active">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ "CONFIG.status.editor.form.flag_case_active" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
            <span *ngIf="element.flag_payment_active" class="badge badge-success">
                {{ 'CASES.list.filters.activity_status.options.active' | translate }}
            </span>
            <span *ngIf="!element.flag_payment_active" class="badge badge-danger">
                {{ 'CASES.list.filters.activity_status.options.inactive' | translate }}
            </span>
            </td>
        </ng-container>
        <!-- Created at -->
        <ng-container matColumnDef="created_at">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'SHARED.model.created_at' | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.created_at | toDateObject | date }}  </td>
        </ng-container>
        <!-- Actions -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{"SHARED.actions" | translate}}</th>
            <td mat-cell *matCellDef="let element">
            <div style="display: flex">
                <p *ngFor="let action of actions" style="margin: 0; padding-right: 10px">
                <!--      Only Mark can edit/delete case status      -->
                <a *ngIf="action === 'Edit'" (click)="editCaseCreditorStatus(element, $event)">{{"SHARED.edit" | translate}}</a>
                <a *ngIf="action === 'Delete'" (click)="authUser.role_id === 5 ? openDeleteDialog(element.id, $event) : ''">
                    {{"SHARED.delete" | translate}}
                </a>
                </p>
            </div>
            </td>
        </ng-container>
        </table>
    
        <div *ngIf="isLoading" class="text-center" style="padding: 50px">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">{{"SHARED.loading" | translate}}...</span>
        </div>
        </div>
    
        <mat-paginator
                [length]="paginatorConfig.length"
                [pageSize]="paginatorConfig.pageSize"
                [pageSizeOptions]="[5, 10, 20]"
                (page)="paginatorChange($event)"
                showFirstLastButtons
        ></mat-paginator>
    </div>
</div>`;
  