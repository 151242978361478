import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { CaseCreditor } from '../../../../../../../../_base-shared/models/Case/CaseCreditor';
import { CaseCreditorProduct } from '../../../../../../../../_base-shared/models/Product/CaseCreditorProduct';
import {
  CaseCreditorPaymentStatus
} from '../../../../../../../../_base-shared/models/Status/CaseCreditorPaymentStatus';
import { CaseCreditorStatus } from '../../../../../../../../_base-shared/models/Status/CaseCreditorStatus';
import { User } from '../../../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../../../_shared/services/main-global-event.service';
import { PackagerStatusService } from '../../../../admin-packager/packager-status/packager-status.service';
import { CallStatusService } from '../../../../call-status/call-status.service';
import { CaseCreditorService } from '../../../../case/case-creditor.service';
import { CaseService } from '../../../../case/case.service';
import { ProductService } from '../../../../case/product.service';
import { CreditorService } from '../../../../creditor/creditor.service';
import { PaymentStatusService } from '../../../../payment-status/payment-status.service';
import { StatusService } from '../../../../status/status.service';

@Component({
  selector: 'app-case-creditor-status-editor',
  templateUrl: './case-creditor-status-editor.component.html',
  styleUrls: ['./case-creditor-status-editor.component.scss']
})
export class CaseCreditorStatusEditorComponent implements OnInit, OnDestroy {
  @Input() case: Case;
  @Input() caseCreditor: CaseCreditor;
  @Output() caseCreditorUpdate: EventEmitter<CaseCreditor>         = new EventEmitter<CaseCreditor>();
  public creditorProducts: Array<CaseCreditorProduct>              = [];
  public creditorStatuses: Array<CaseCreditorStatus>               = [];
  public creditorPaymentStatuses: Array<CaseCreditorPaymentStatus> = [];
  public serverResponse: LaravelResourceResponse;
  public isLoading                                                 = 0;
  public isSubmitting: boolean;
  public isEditing                                                 = false;
  private authUser: User;
  protected subscriptions: Array<Subscription>                     = [];
  public form: UntypedFormGroup;

  public legalPaymentStatuses: Array<CaseCreditorPaymentStatus> = [];
  public claimPaymentStatuses: Array<CaseCreditorPaymentStatus> = [];

  constructor(
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private translate: TranslateService,
    private globalEventsService: MainGlobalEventService,
    private productService: ProductService,
    private caseService: CaseService,
    private statusService: StatusService,
    private paymentStatusService: PaymentStatusService,
    private callStatusService: CallStatusService,
    private packagerStatusService: PackagerStatusService,
    private creditorService: CreditorService,
    private caseCreditorService: CaseCreditorService
  ) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);

    this.fetchCreditorProducts();
    this.fetchCreditorStatuses();
    this.fetchCreditorPaymentStatuses();
    this.loadCaseRelations(this.case);
    this.loadCaseCreditorRelations(this.case, this.caseCreditor);
    this.buildForm();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public switchEdit($event = null) {
    if ($event) {
      $event.preventDefault();
    }
    this.isEditing = !this.isEditing;
    this.enableDisableForm(this.isEditing);
  }

  private fetchCreditorProducts() {
    this.isLoading++;
    this.creditorService.getCaseCreditorProducts().pipe(finalize(() => this.isLoading--)).subscribe(
      result => this.creditorProducts = result.data,
    );
  }

  private fetchCreditorStatuses() {
    this.isLoading++;
    this.caseCreditorService.getCaseCreditorStatuses({select_all: 1}, [], ['name']).pipe(finalize(() => this.isLoading--)).subscribe(
      result => this.creditorStatuses = result.data,
      error => this.toastr.error(this.translate.instant('SHARED.error'))
    );
  }

  private fetchCreditorPaymentStatuses() {
    this.isLoading++;
    this.caseCreditorService.getCaseCreditorPaymentStatuses({select_all: 1}, [], ['name'])
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        result => {
          const type = ['claim', 'legal'];
          this.creditorPaymentStatuses = result.data.filter(status => type.includes(status.type));
          // this.allPaymentStatuses = result.data;
          this.claimPaymentStatuses = this.creditorPaymentStatuses.filter(status => status.type === 'claim');
          this.legalPaymentStatuses = this.creditorPaymentStatuses.filter(status => status.type === 'legal');
        }),
      error => this.toastr.error(this.translate.instant('SHARED.error'))
  }

  private buildForm() {
    this.form = this.fb.group({
      status_id: [{value: this.caseCreditor.status_id, disabled: true}, Validators.required],
      payment_status_id: [{value: this.caseCreditor.payment_status_id, disabled: true}, Validators.required],
      legal_payment_status_id: [{value: this.caseCreditor.legal_payment_status_id, disabled: true}],
      case_creditor_product_id: [
        {
          value: this.caseCreditor.case_creditor_product_id,
          disabled: true
        },
        [Validators.required]
      ],
    });
  }

  private enableDisableForm(isEnabled: boolean) {
    if (isEnabled) {
      this.form.get('case_creditor_product_id').enable();
      this.form.get('status_id').enable();
      this.form.get('payment_status_id').enable();
      this.form.get('legal_payment_status_id').enable();
    } else {
      this.form.get('case_creditor_product_id').disable();
      this.form.get('status_id').disable();
      this.form.get('payment_status_id').disable();
      this.form.get('legal_payment_status_id').disable();
    }
  }

  public submitForm(form: UntypedFormGroup) {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }
    let caseCreditorWasUpdated = false;

    this.isSubmitting = true;
    this.caseCreditorService.updateCaseCreditor(this.case.id, this.caseCreditor.id, form.value)
      .pipe(finalize(() => this.isSubmitting = false)).subscribe(
      result => {
        this.switchEdit();
        this.toastr.success(this.translate.instant('CASES.single.general.status_editor.result.status_updated'));
        const oldCaseCreditor     = {...this.caseCreditor};
        const updatedCaseCreditor = result.data;
        if (oldCaseCreditor.status_id !== updatedCaseCreditor.status_id) {
          this.caseCreditor.status_id = updatedCaseCreditor.status_id;
          this.caseCreditor.status    = this.creditorStatuses.find(
            fStatus => fStatus.id === updatedCaseCreditor.status_id,
          );
          caseCreditorWasUpdated      = true;
        }

        if (oldCaseCreditor.case_creditor_product_id !== updatedCaseCreditor.case_creditor_product_id) {
          this.caseCreditor.case_creditor_product_id = updatedCaseCreditor.case_creditor_product_id;
          this.caseCreditor.case_creditor_product    = this.creditorProducts.find(product => product.id === updatedCaseCreditor.case_creditor_product_id);
          caseCreditorWasUpdated                     = true;
        }
        if (caseCreditorWasUpdated) {
          this.caseCreditorUpdate.emit(this.caseCreditor);
        }
      },
      err => {
        this.serverResponse = err.error;
        this.toastr.error(this.translate.instant('SHARED.went-wrong'));
      },
    );
  }

  private loadCaseRelations(clientCase: Case) {
    const requiredRelations = [];
    let missingRelations    = [];
    requiredRelations.forEach(relation => {
      if (!clientCase.hasOwnProperty(relation)) {
        missingRelations.push(relation);
      }
    })

    if (!missingRelations.length) {
      return;
    }

    this.isLoading++;
    this.subscriptions.push(
      this.caseService.get(clientCase.id, missingRelations).pipe(finalize(() => this.isLoading--)).subscribe(
        result => {
          missingRelations.forEach(relation => this.case[relation] = result.data[relation])
        },
      ),
    );
  }

  private loadCaseCreditorRelations(clientCase: Case, caseCreditor: CaseCreditor) {
    const requiredRelations = ['status', 'creditor', 'case_creditor_product', 'appointed_court.court', 'case_creditor_entities'];
    let missingRelations    = [];
    requiredRelations.forEach(relation => {
      if (!caseCreditor.hasOwnProperty(relation)) {
        console.log(relation);
        missingRelations.push(relation);
      }
    })

    if (!missingRelations.length) {
      return;
    }

    this.isLoading++;
    this.subscriptions.push(
      this.caseCreditorService.showCaseCreditor(clientCase.id, caseCreditor.id, missingRelations).pipe(finalize(() => this.isLoading--)).subscribe(
        result => {
          missingRelations.forEach(relation => this.caseCreditor[relation] = result.data[relation])
        },
      ),
    );
  }
}
