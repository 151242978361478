<h1 mat-dialog-title>{{ title }}</h1>
<div class="pt-3">
  <form [formGroup]="form" (ngSubmit)="sendReportData()">
    <div class="row">
      <div class="col-6">
        <app-input type="select" formControlName="period_selector" appearance="standard"
                   [fullWidth]="true"
                   label="Select range ..." [showClear]="true"
                   [selectOptions]="monthNames" [selectLabel]="'name'" [selectValue]="'number'">
        </app-input>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <app-input type="mat-datepicker" formControlName="last_term_start_date" appearance="fill" class="mr-1"
                  [label]="'REPORTS.run-off-reports.last_term_start_date' | translate" >
        </app-input>
      </div>
      <div class="col-6">
        <app-input type="mat-datepicker" formControlName="last_term_end_date" appearance="fill" class="mr-1"
                  [label]="'REPORTS.run-off-reports.last_term_end_date' | translate" >
        </app-input>
      </div>
    </div>
    <div *ngIf="endPoint === 'clientCreditorReport'">
      <app-input type="select" formControlName="case_creditor_product_id" width="160px"
            selectLabel="name_es" selectValue="id" [selectOptions]="caseCreditorProducts" [extraLabel]="true"
            [label]="'CASE_CREDITOR.model.case_creditor_product' | translate" [showClear]="true" [searchable]="true">
      </app-input>
    </div>
    <div class='pt-2 text-right'>
      <button type="button" mat-button color="primary" class="mt-3 ml-2" (click)="dialogRef.close()">
        {{ "REPORTS.financial-reports.list.run-off-report.actions.close" | translate }}
      </button>
      <button type="submit" mat-raised-button color="primary" class="mt-3">
        {{ "REPORTS.financial-reports.list.run-off-report.actions.submit" | translate }}
        <span *ngIf="isLoading" class="spinner-border spinner-border-sm text-white" role="status">
                    <span class="sr-only">Loading...</span>
                </span>
      </button>
    </div>
  </form>
</div>
